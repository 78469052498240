// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$-russia-name: 'Russia';
$-russia-x: 0px;
$-russia-y: 0px;
$-russia-offset-x: 0px;
$-russia-offset-y: 0px;
$-russia-width: 16px;
$-russia-height: 16px;
$-russia-total-width: 32px;
$-russia-total-height: 32px;
$-russia-image: '../_img/sprite.png';
$-russia: (0px, 0px, 0px, 0px, 16px, 16px, 32px, 32px, '../_img/sprite.png', 'Russia', );
$-ukraine-name: 'Ukraine';
$-ukraine-x: 16px;
$-ukraine-y: 0px;
$-ukraine-offset-x: -16px;
$-ukraine-offset-y: 0px;
$-ukraine-width: 16px;
$-ukraine-height: 16px;
$-ukraine-total-width: 32px;
$-ukraine-total-height: 32px;
$-ukraine-image: '../_img/sprite.png';
$-ukraine: (16px, 0px, -16px, 0px, 16px, 16px, 32px, 32px, '../_img/sprite.png', 'Ukraine', );
$-united-states-name: 'United-States';
$-united-states-x: 0px;
$-united-states-y: 16px;
$-united-states-offset-x: 0px;
$-united-states-offset-y: -16px;
$-united-states-width: 16px;
$-united-states-height: 16px;
$-united-states-total-width: 32px;
$-united-states-total-height: 32px;
$-united-states-image: '../_img/sprite.png';
$-united-states: (0px, 16px, 0px, -16px, 16px, 16px, 32px, 32px, '../_img/sprite.png', 'United-States', );
$spritesheet-width: 32px;
$spritesheet-height: 32px;
$spritesheet-image: '../_img/sprite.png';
$spritesheet-sprites: ($-russia, $-ukraine, $-united-states, );
$spritesheet: (32px, 32px, '../_img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
