.product-fullinfo{

    &__item{
        &:not(:last-child){
            margin-bottom: 40px;
        }
    }

    &__header{
        padding: 15px 25px;
        margin-bottom: 1.2rem;
        background: $theme-secondary-color;
    }

    &__title{
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1;
        color: $widget-primary-title-color;
    }	

}

.product-tabs{
    &__btn-list{
        font-size: 0;
        border-bottom: 1px solid $base-border-color;
    }
    &__item{
        margin-left: -1px;
        position: relative;
        top: 1px;
    }    
    &__btn-list>*{
        display: inline-block;
        font-size: 1rem;
    }    
    &__btn{
        font-size: 12px;
        line-height: 42px;
        padding: 0 32px;
        background: $theme-secondary-color;
        border: $page-fgroup-border;
        text-decoration: $base-second-link-decor;
        display: block;
        box-sizing: border-box;
        color: $base-second-link-color;
        font-weight: 700;
        text-transform: uppercase;
    }
    &__item--active &__btn{
        background: #fff;
        border-bottom: 1px solid #fff;
        border-top-color: $theme-main-color;
        box-shadow: inset 0 1px 0 0 #f59e20;
        cursor: default;
    }
    
    &__section {
        display: none;
        padding-top: 30px;
        &--active {
            display: block;
        }
    }
}
