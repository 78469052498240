.small-post {

  &__image {
    display: block;
    margin-bottom: 5px;
  }

  @media (min-width: $screen-sm-min) {

    display: table;
    width: 100%;
    table-layout: fixed;

    &__image {
      display: table-cell;
      vertical-align: top;
      width: 30%;
      padding-right: 10px;
      margin-bottom: 0;

      img {
        max-width: 100%;
      }
    }

    &__inner {
      display: table-cell;
      vertical-align: top;
    }
  }

  &__date {
    display: block;
    color: $base-font-color-secondary;
    padding-bottom: 3px;
  }

  &__title {

    font-size: 1.3em;

    &-link {
      @include link-main;
    }
  }

  &__desc {
    padding-top: 5px;
  }

  &__readmore {
    @include link-main;
    display: block;
    margin-top: 5px;
    white-space: nowrap;

    &:after {
      content: '>';
      margin-left: 5px;
    }

  }

}