@mixin banner-group-border($pseudo, $border, $main-side, $direction: horizontal) {
  position: relative;

  &::#{$pseudo} {
    content:       "";

    position:      absolute;
    #{$main-side}: 0;
    z-index:       1;

    background:    nth($border, 3);

    @if $direction == "horizontal" {
      left: 0;
      width:  100%;
      height: nth($border, 1);
    } @else {
      @if $direction == "vertical" {
        top: 0;
        height: 100%;
        width:  nth($border, 1);
      } @else {
        @warn "banner-group-border mixin: wrong direction"
      }
    }
  }
}

.banner-group {
  $group-width: 1440;
  $left-banner-width: 240;
  $main-banner-width: 740;
  $right-banner-width: 460;
  $banners-height: 250px;
  $border: 2px solid #ffffff;

  position:     relative;

  border-left:  $border;
  border-right: $border;

  @include banner-group-border(before, $border, top, horizontal);
  @include banner-group-border(after, $border, bottom, horizontal);

  &__main {
    @include banner-group-border(after, $border, bottom, horizontal);

    @media (min-width: $screen-md-min) {
      @include banner-group-border(before, $border, right, vertical);

      &::after {
        display: none;
      }
    }
  }

  &__left {
    @include banner-group-border(after, $border, right, vertical);
  }

  &__right-top {
    @include banner-group-border(after, $border, bottom, horizontal);
  }

  &__right-bot {

  }

}