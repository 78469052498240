.widget-secondary{
	
	&__header{
		margin-bottom: 1.2rem;
		font-size: 0;
	}

	&__title{
		display: inline-block;
		vertical-align: baseline;
		font-size: 1.5rem;
		line-height: 1;
		color: $widget-secondary-title-color;
	}

	&__viewall{
		display: inline-block;
		vertical-align: baseline;
		font-size: 1rem;
		
		&:before{
			content: '/';
			padding: 0 10px;
		}
	}

	&__hlink{
		@include link-main;
	}

}