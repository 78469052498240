//Countries flags icons
.ico-flag {
  display: inline-block;

  &--ru {
    @include sprite($-russia);
  }

  &--en {
    @include sprite($-united-states);
  }

  &--ua {
    @include sprite($-ukraine);
  }
}