.offline{
  @include absolute-center-translate;
  font-size: 1rem;
  text-align: center;

  &__title{
    font-size: 4em;
    color: #000;
    margin-bottom: 0.3em;
    line-height: 1;
  }

  &__intro{
    font-size: 1.7em;
     margin-bottom: 0.3em;
  }

  &__text{

  }
}