.svg-icon {
  max-width: 100%;
  max-height: 100%;

  fill: currentColor;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}