.change-view {
  display: table;

  &__button {
    width: 35px;
    height: 30px;
    position: relative;

    background: #FFF;
    border: 1px solid $base-border-color;

    &:not(:first-child) {
      border-left: 0;
    }

    &:active {
      box-shadow: inset 0 1px 3px #BDC3CA;
    }
    &[disabled] {
      background-color: #D1D7DF;
      box-shadow: inset 0 1px 3px #BDC3CA;
    }
  }
  &__icon {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: #7C7C7C;
    color: #7C7C7C;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  &__button[disabled] &__icon {
    fill: #FFF;
    color: #FFF;
  }
}